import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import { H3 } from '../components/common/H.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import { P } from '../components/common/P.style';

import SubLayout from '../components/SubLayout';
import SymptomEng from '../components/SymptomEng';
import WeeklySchedule from '../components/WeeklySchedule';
import ClosedDayLabel from '../components/ClosedDayLabel';
import Online365Label from '../components/Online365Label';
import AccessInfoArea from '../components/AccessInfoArea';
import Form from '../components/Form';

import telemed from '../assets/img/telemed-link.jpg';

import { useTrackedState } from '../lib/state';

const English = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="Welcome to HIRAHATA CLINIC!">
      <Link to="https://telemed-app.hirahata-clinic.or.jp/en_index.html" external>
        <Img src={telemed} />
      </Link>
      <H3 size={size}>Treatment for Suspected Covid-19 Aftereffects</H3>
      <Flex>
        <FlexItem css={`
            margin: 0 1.5em;
            line-height: 1.8;
            font-size: 18px;
        `}>
        Since around February 2020, numerous cases of long-lasting symptoms (ex. low degree fever, malaise, exhaustion, numbness, respiratory discomfort, headache, and loss of appetite) have been reported around the world. HIRAHATA CLINIC is treating patients with these symptoms, combining Chinese traditional herbal medicine and western medicine. Hokkaido to Kyushu - we see many patients from all across Japan via online telemedicine service.<br /><br />
        Talk with our doctors online about your concerns
        <Link to="https://telemed-app.hirahata-clinic.or.jp/en_index.html" external> (https://telemed-app.hirahata-clinic.or.jp/en_index.html)
        </Link>.
         If you suspect you have the symptoms related to Covid-19 aftereffects, please specify "Dr. Hirahata" when choosing a doctor.
        <br />
        <P css={`
            margin-top: 2em;
            text-align: center;
        `}>
        <Link to="https://telemed-app.hirahata-clinic.or.jp/en_index.html" external>
          You may use the online telemedicine service even if you have never been to the clinic.<br />
        <Img src={telemed} />
        </Link>
        </P>
      </FlexItem>
      <SymptomEng
        list={[
          'Long-term low degree fever (intermittent including)',
          'Malaise, Fatigue',
          'Loss of appetite',
          'Coughing, Asthma-like symptoms',
          'Long-term diarrhea',
          'Abdominal pain',
          'Hot flash, Feeling of blood pressure rising',
          'Heavy head',
          'Chest discomfort/pain',
          'Joint pain',
          'Chills',
          'Protruding veins in limbs',
          'Rash',
        ]}
      />
      </Flex>
      <H3 size={size}>Services</H3>
      <UL>
        <LI>General Internal Medicine</LI>
        <LI>Diabetes</LI>
        <LI>Cardiology</LI>
        <LI>Pulmonology</LI>
        <LI>Pancreas Care</LI>
        <LI>Herbal Medicine (Kampo)</LI>
        <LI>Endoscopic Examination</LI>
        <LI>Ultrasound Test</LI>
        <LI>Blood Test</LI>
        <LI>Vaccination</LI>
      </UL>
      <H3 size={size}>Hours</H3>
      <Flex center column>
        <FlexItem>
          <WeeklySchedule size={size} headerBackgroundColor="#efefef" lang="en" />
          <FlexItem>
            <Flex column>
              <ClosedDayLabel lang="en" />
              <Online365Label lang="en" />
              <span>* Cardiology: Monday, Tuesday, Thursday, Friday: 10:00-18:00</span>
            </Flex>
          </FlexItem>
        </FlexItem>
      </Flex>
      <H3 size={size}>Access</H3>
      <AccessInfoArea lang="en" />
      <H3 size={size}>Featured Media</H3>
        <P>
          - JAPANESE ONLY -<br />
          <Link to="https://www.nhk.or.jp/gendai/articles/4475/" external >NHK Close Up Gendai Plus, October 27, 2020</Link><br />
          <Link to="https://news.yahoo.co.jp/articles/27b84387755505239c24a97c766754e33e499ee3?page=1" external >News Postseven, September 4, 2020</Link><br />
          <Link to="https://www.youtube.com/watch?v=stS8lvu4v48&feature=youtu.be" external >News Zero, December 4, 2020</Link><br />
          <Link to="https://mainichi.jp/articles/20201129/k00/00m/040/200000c" external >The Mainichi, November 29, 2020</Link><br />
          <Link to="https://news.yahoo.co.jp/articles/72e9a988c25ca22347741823a17fdc64972081d3" external >FLASH, December 4, 2020</Link><br />
        </P>
      <H3 size={size}>Contact</H3>
      <Flex center>
        <FlexItem
          css={`
            margin-top: 20px;
            margin-bottom: 80px;
            width: 96%;
          `}
        >
          <Form lang="en" />
        </FlexItem>
      </Flex>
    </SubLayout>
  );
};

export default English;
