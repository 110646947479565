import React from 'react';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import ListItem from './ListItem';

import Card from './Card';

const SymptomEng = ({ list }) => (
  <Card width="300px">
    <Flex column>
      <Flex
        css={`
          margin-bottom: 10px;
        `}
      >
        <Flex center>
          <FontAwesomeIcon icon={faUser} style={{ fontSize: 16, marginRight: 5 }} color="#000" />
        </Flex>
        <Flex center>Symptoms</Flex>
      </Flex>
      <Flex column>
        {list.map(item => (
          <ListItem key={item} label={item} iconColor="#f0862e" />
        ))}
      </Flex>
    </Flex>
  </Card>
);

export default SymptomEng;
